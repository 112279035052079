/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';

var certification_slider = new Swiper(".slider-certification .swiper", {
  grabCursor: true,
  spaceBetween: 25,
  slidesPerView: 1.5,
  centeredSlides: true,
  loop: true,
  breakpoints: {
    576: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    992: {
      centeredSlides: false,
      slidesPerView: 4,
      loop: false,
    },
  },
});

var percentage_slider = new Swiper(".slider-percentage .swiper", {
  grabCursor: true,
  spaceBetween: 10,
  slidesPerView: 1.3,
  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 'auto',
      spaceBetween: 30,
    },
  },
});

/* Slider */
var carousel_slide = new Swiper('.carousel-swiper', {
  slidesPerView: 1.25,
  spaceBetween: 20,
  centeredSlides: false,
  navigation: {
    nextEl: '.carousel-next',
    prevEl: '.carousel-prev',
  },

  breakpoints: {
    992: {
      spaceBetween: 40,
    },
  },
});

/* Actus */
var actus_slide = new Swiper('.actus-swiper', {
  slidesPerView: "auto",
  spaceBetween: 20,
  centeredSlides: false,
  navigation: {
    nextEl: '.actus-next',
    prevEl: '.actus-prev',
  },
});

function scaleSlides() {
  const slides = document.querySelectorAll('.engagements-swiper .swiper-slide');
  slides.forEach((slide) => {
    slide.style.transform = 'scale(1)';
  });

  const nextSlide = document.querySelector('.engagements-swiper .swiper-slide-next');
  if (nextSlide) {
    nextSlide.style.transform = 'scale(0.8)';
  }
}

/* Engagements */
var engagements_slide = new Swiper('.engagements-swiper', {
  slidesPerView: 2,
  centeredSlides: false,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  on: {
    slideChangeTransitionStart: function () {
      scaleSlides();
    },
    init: function () {
      scaleSlides();
    },
  },
});

/* Vins */
var wine_slider = new Swiper('.wines-swiper', {
  slidesPerView: 2,
  centeredSlides: false,
  grabCursor: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },
});

/* Values */
var values_slide = new Swiper('.values-swiper', {
  slidesPerView: "auto",
  centeredSlides: false,
  loop: "true",
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  slideToClickedSlide: true,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
    renderFraction: function (currentClass, totalClass) {
      return (
        "<div class=home-hero-pagination>" + '<span class="' + currentClass + '"></span>' +
        " <div class=pagination-tiret></div> " +
        '<span class="' + totalClass + '"></span>' + "</div>"
      );
    },
  },

  on: {
    init: function() {
      // Récupère la slide active
      let activeSlide = $(".values-slide.swiper-slide-active");
      let activeSlideIndex = activeSlide.data("id");

      // Crée un tableau d'images
      let images = $(".header-img");
      let imagesArr= Array.from(images);

      // Récupère l'image correspondante à la slide active
      let activeImage = imagesArr.filter(image => image.getAttribute("data-id") == activeSlideIndex );

      // Ajoute le "fade-in effect" à l'image associée
      if(activeImage[0].getAttribute("data-id") == activeSlideIndex) {
        activeImage[0].classList.add("fade-in-image");
      }
    },


    slideChangeTransitionStart: function() {
      // Récupère la slide active
      let activeSlide = $(".values-slide.swiper-slide-active");
      let activeSlideIndex = activeSlide.data("id");

      // Crée un tableau d'images
      let images = $(".header-img");
      let imagesArr= Array.from(images);

      // Récupère l'image correspondante à la slide active
      let activeImage = imagesArr.filter(image => image.getAttribute("data-id") == activeSlideIndex );

      // Ajoute le "fade-in effect" à l'image associée
      if(activeImage[0].getAttribute("data-id") == activeSlideIndex) {
        $(".fade-in-image")[0].classList.remove("fade-in-image");
        activeImage[0].classList.add("fade-in-image");
      }
    },
  },
});

/* eslint-enable no-unused-vars */
