const $window = $(window);
const body = $('body');
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";
const mainMenu = $('#main-menu');

let lastScroll = 0;

let stickyHeader = () => {
    if (mainMenu.hasClass('show')) {
        return;
    }

    const currentScroll = window.scrollY;

    if (currentScroll === 0) {
        body.removeClass(scrollUp);
        body.removeClass(scrollDown);
    } else if (currentScroll > lastScroll && !body.hasClass(scrollDown)) {
        // Scroll vers le bas
        body.removeClass(scrollUp);
        body.addClass(scrollDown);
    } else if (currentScroll < lastScroll && !body.hasClass(scrollUp)) {
        // Scroll vers le haut
        body.removeClass(scrollDown);
        body.addClass(scrollUp);
    }

    lastScroll = currentScroll;
};

$window.on('load scroll', () => {
    stickyHeader();
});
