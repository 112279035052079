// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/offcanvas';
import 'js-cookie';

/*
 * Modules
 */
import './modules/hamburger';
import './modules/sliders';
import './modules/gsap';
import './modules/popups';
import './modules/sticky-header';
import './collections';
import './expertises';
